<template>
  <div>
    <wd-navbar>
      <div slot="left" class="middle" @click="$goBack">
        <wd-icon name="arrow-left" />
      </div>
      <div>
        <span>订单支付</span>
      </div>
    </wd-navbar>
    <div>
      <wd-cell-group border>
        <wd-cell>
          <span slot="title" class="paytitle">缴费名称</span>
          <span class="payname">{{payObject.acctName}}</span>
        </wd-cell>
        <wd-cell>
          <span slot="title" class="paytitle">缴费类型</span>
          <span class="payname">{{payObject.acctType == '0' ? '电表' : payObject.acctType == '1' ? '水表' : payObject.acctType == '2' ? '房间费' : payObject.acctType == '3' ? '物业费' : payObject.acctType == '4' ? '停车费' : '组合缴费'}}</span>
        </wd-cell>
        <wd-cell>
          <span slot="title" class="paytitle">当前余额</span>
          <span class="payname">{{payObject.remainingBalance || '0'}}</span>
        </wd-cell>
        <wd-cell class="payWrap">
          <span slot="title" class="paytitle" style="white-space: nowrap">缴费金额</span>
          <wd-radio-group v-model="payInfo.amount" shape="button" @change="changeAmount">
            <wd-radio :value="config.oneRechargeFee">{{config.oneRechargeFee}}</wd-radio>
            <wd-radio :value="config.twoRechargeFee">{{config.twoRechargeFee}}</wd-radio>
            <wd-radio :value="config.threeRechargeFee">{{config.threeRechargeFee}}</wd-radio>
          </wd-radio-group>
          <input class="amount" type="number" placeholder="自定义金额" style="width: 70px" v-model="payInfo.feeAmount" />
        </wd-cell>
      </wd-cell-group>
      <div style="padding: 7px 0 7px 12px; background-color: #f6f6f6; font-size: 14px">
        支付方式
      </div>
      <wd-radio-group v-model="payType" cell>
        <wd-radio value="1" class="radioBtn"><img src="../../assets/img/wechatpay.svg" style="width: 25px; margin-right: 10px" />微信</wd-radio>
        <wd-radio value="2" class="radioBtn"><img src="../../assets/img/alipay.svg" style="width: 25px; margin-right: 10px" />支付宝</wd-radio>
      </wd-radio-group>
    </div>
    <div class="fixBtn">
      <div class="amount">充值金额：<img src="../../assets/img/money.svg" style="max-width: 20px" /><font>{{(payInfo.feeAmount && payInfo.feeAmount >= 0.01) ? payInfo.feeAmount : payInfo.amount}}</font></div>
      <div class="btn">
        <wd-button type="success" :loading="loadingBtn" @click="handleOrder"><img src="../../assets/img/dopay.svg" alt="" style="width: 16px; margin-right: 5px"> 确认支付</wd-button>
      </div>
    </div>
  </div>
</template>

<script>
  import wx from 'weixin-jsapi'
  export default {
    watch: {
        "payInfo.feeAmount"(value){
            if( value >= 0.01 ) {
                this.payInfo.amount = null
            }
        },
    },
    data(){
        return {
            loadingBtn: false,
            payInfo: {
                amount: 0,
                feeAmount: ""
            },
            payType: '1',
            value1: '10',
            payObject: {},
            order: {
                roleId: null,
                acctId: null,
                orderFee: null,
                payFee: null,
                userId: null,
                acctType: null
            },
            config: {
                miniRechargeFee: 10,
                oneRechargeFee: 10,
                twoRechargeFee: 50,
                threeRechargeFee: 100
            },
            orderId: null
        }
    },
    created() {
        // this.payType = this.$isWeiXin ? "1" : "2"
        this.payInfo.amount = this.config.oneRechargeFee
        this.payObject = this.$route.params
        if( !this.payObject.acctId ) {
          this.$router.push('/pay')
        }
        this.order.userId = localStorage.getItem("appUserId")
        this.order.roleId = localStorage.getItem("roleKey")
        this.order.acctId = this.payObject.acctId
        this.order.acctType = this.payObject.acctType
        this.getConfig()
    },
    methods: {
        getConfig(){
            this.$http.get("/external/appFeeConfig/list", { params: { roleId: this.order.roleId } }).then(res=>{
                if( res.rows && res.rows.length > 0 ) {
                    this.config = res.rows[0]
                    this.payInfo.amount = this.config.oneRechargeFee
                }
            })
        },
        changeAmount(val){
            this.payInfo.feeAmount = null
        },
        handleOrder(){
            if( !this.payInfo.feeAmount && !this.payInfo.amount ) {
                this.$toast.error("请选择或输入金额！")
                return false
            }
            let amount = this.payInfo.feeAmount ? this.payInfo.feeAmount : this.payInfo.amount ? this.payInfo.amount : null
            if( amount < 0.01 ) {
                this.$toast.error("支付金额不能少于0.01元！")
                return false
            }
            if( amount < this.config.miniRechargeFee ) {
                this.$toast.error("支付金额不能少于"+this.config.miniRechargeFee+"元！")
                return false
            }
            this.order.orderFee = amount
            this.order.payFee   = amount
            this.loadingBtn     = true


            this.$http.post("/payment/union/createOrder", this.order).then(res=>{
                if( res.code == 200 ) {
                    this.orderId = res.orderId
                    if( this.payType == '1' ) {
                      this.doPay()
                    } else if( this.payType == '2' ) {
                      this.aliPay()
                    }
                } else {
                    this.loadingBtn = false
                    this.$toast.error(res.msg)
                }
            })
        },
        aliPay(){
            if( this.$isWeiXin ) {
              // 跳转到打开游览器调起支付宝
              this.$router.push({ path: '/alipay', query: { orderId: this.orderId, roleId: this.order.roleId } })
            } else {
              let openid = localStorage.getItem("phoneNum")
              let data = { roleId: this.order.roleId, openId: openid, orderId: this.orderId, formData: 1, acctType: this.order.acctType }
              // 游览器直接调起支付宝支付
              this.$http.post("/payment/alipay/dopay", data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then(res=>{
                  console.log(res, "啥回事呀")
                  if( res.code == 500 ) {
                      this.$toast.error(res.msg)
                      this.loadingBtn = false
                  } else {
                      const div = document.createElement('div')
                      div.innerHTML = res
                      document.body.appendChild(div)
                      document.forms[0].submit()
                  }
              })
            }
        },
        doPay(){
            if( !this.$isWeiXin ) {
                let openid = localStorage.getItem("phoneNum")
                let data = {
                    roleId: this.order.roleId,
                    orderId: this.orderId,
                    openId: openid,
                    acctType: this.order.acctType
                }
                this.$http.post("/payment/wxpay/doH5pay", data).then(res=>{
                    if( res.code == 200 ) {
                        this.loadingBtn = false
                        window.location.replace(res.webUrl)
                    } else {
                        this.loadingBtn = false
                        this.$toast.error(res.msg)
                    }
                })
            } else {
                let openid = localStorage.getItem("openId")
                let data = {
                    roleId: this.order.roleId,
                    orderId: this.orderId,
                    openId: openid,
                    acctType: this.order.acctType
                }
                this.$http.post("/payment/wxpay/dopay", data).then(res=>{
                    if( res.code == 200 ) {
                        this.wechatPay(res.data)
                    } else {
                        this.loadingBtn = false
                        this.$toast.error(res.msg)
                    }
                })
            }
        },
        wechatPay(config){
          let that = this
          let params = {
              debug: false,
              appId: config.appId,
              timeStamp: config.timeStamp,
              nonceStr: config.nonceStr,
              signature: config.paySign,
              jsApiList: ['chooseWXPay']
          }
          wx.config(params);
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success:function(res){ console.log(res, "success") },
              fail:function(res){ console.error(res, "fail") }
            })
            wx.chooseWXPay({
                timestamp: config.timeStamp,
                nonceStr: config.nonceStr, // 支付签名随机串，不长于 32 位
                package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: config.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: config.paySign, // 支付签名
                success: function (res) {  // 支付成功后的回调函数
                  console.log(res, "支付成功！")
                  that.$toast.success("支付成功！")
                  that.$router.push('/home')
                },
                fail: function (res) {
                  that.$toast.error(res.errMsg)
                },
                cancel: function (res) {
                  // 用户取消支付
                  that.loadingBtn = false
                }
            })
          })
        }
    },
  }
</script>

<style lang="less" scoped>
  .paytitle{
    color: #1e1e1e;
    font-size: 14px;
  }
  .payname{
    color: #888;
    font-size: 13px;
  }
  .radioBtn{
    /deep/ .wd-radio__label{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .payWrap{
    /deep/ .wd-cell__wrapper{
      padding: 10px 15px 10px 0;
      .wd-cell__right{
        flex: 3.5;
        .wd-cell__value{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .wd-radio{
            margin-right: 5px;
            .wd-radio__label{
              padding: 5px 10px;
              min-width: 45px;
            }
          }
        }
      }
      .wd-cell__left{
        height: 32px;
        line-height: 32px;
      }
      .amount{
        height: 30px;
        border-radius: 16px;
        border: 1px solid #f6f6f6;
        margin-left: 10px;
        text-align: center;
        color: #666666;
        font-size: 16px;
        &::-webkit-input-placeholder{
          font-size: 12px;
        }
        &:active,&:focus{
          border: 1px solid #f6f6f6;
        }
      }
    }
  }
  .fixBtn{
    position: fixed;
    bottom: 0;
    padding: 5px 10px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-top: 1px solid #f6f6f6;
    .amount{
      display: flex;
      align-items: center;
      font-size: 14px;
      font{
        font-size: 26px;
        color: #42b983;
      }
    }
    .btn{
      /deep/ .wd-button{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /deep/ .wd-button__txt{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
